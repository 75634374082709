import { LangEnum } from "controllers/admin-expo/enums";
import { BACKEND_HOST, kyRequestBase, responseCallback } from "api/api";
import { Event } from "controllers/admin-event/interfaces/admin-event";
import { format } from "date-fns";
import { selectByLang } from "../../../../components/FormEvent/FormEvent";

export const apiEventSave = async (event: Event, lang: LangEnum = LangEnum.RU) => {
    const data = getEventFormData({ ...event }, lang);
    if (event?.id > 0) data.append("_method", "PUT");
    const response = await kyRequestBase
        .post(`${BACKEND_HOST}/event` + (event?.id > 0 ? `/${event.id}` : ""), {
            body: data,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                "Accept-Language": lang,
            },
        })
        .catch(e => {
            console.log("event", `${e.name}: ${e.message}`);
            return e;
        });
    return responseCallback(response);
};

export const getEventFormData = (formData: any, lang: LangEnum) => {
    const data = new FormData();
    const name = selectByLang(formData.name, lang, formData.name);
    name && data.append("name", name);

    const description = selectByLang(formData.description, lang, formData.description);
    formData.hasOwnProperty("description") && data.append("description", description || "");

    const forumThemes = selectByLang(formData.forum_themes, lang, formData.forum_themes);
    for (let i = 0; i < forumThemes?.length; i++) {
        if (Number.isInteger(Number.parseInt(`${forumThemes[i]}`))) {
            data.append("forum_themes[]", forumThemes[i]);
        } else data.append("forum_themes", "");
    }

    // const date = selectLangValue(formData.date, lang);
    // date && data.append("date", date);
    ["date", "end_date"].forEach((v: string) => {
        if (formData[v]) {
            data.append(v, serverDateFormat(formData[v]));
        }
    });

    const curator = selectByLang(formData.curator, lang, formData.curator);
    curator && data.append("curator", curator);

    formData.hasOwnProperty("has_chat") && data.append("has_chat", formData.has_chat ? "1" : "0");

    const scene = selectByLang(formData.scene, lang, formData.scene);
    (scene || scene === "") && data.append("scene", scene);
    // debugger;
    const speakers = formData.speakers;
    if (lang === LangEnum.RU) {
        for (let i = 0; i < speakers?.length; i++) {
            data.append("speakers[]", String(speakers[i].id));
        }
    }

    const stream_link = selectByLang(formData.stream_link, lang, formData.stream_link);
    stream_link !== null && data.append("stream_link", stream_link);

    const type = selectByLang(formData.type, lang) || formData.type;
    Number.isInteger(Number.parseInt(type)) && data.append("type", type);

    const place = selectByLang(formData.place, lang, formData.place);
    place && data.append("place", place);
    if (lang === LangEnum.RU) {
        let reports = [] as any[];
        if (formData.reports?.length > 0)
            for (let i = 0; i < formData.reports?.length; i++) {
                Object.keys(formData.reports[i]).forEach(k => reports.push(formData.reports[i][k]));
            }
        else {
            Object.keys(formData).forEach(key => {
                if (key !== "reports" && key.indexOf("reports") !== -1) reports.push(formData[key]);
            });
        }
        reports.forEach((r, i) => {
            if (!r) {
                data.append("reports[]", "null");
                return;
            }
            ["id", "online", "position"].forEach(key => {
                data.append(`reports[${i}][${key}]`, String(r[key]));
            });
            const date = r.date;
            if (date) data.append(`reports[${i}][date]`, date + ":00");
            const end_date = r.end_date;
            if (end_date) data.append(`reports[${i}][end_date]`, end_date + ":00");
        });
    }

    return data;
};

export const serverDateFormat = (date: any) => {
    return !date ? null : format(typeof date === "number" ? new Date(date) : date, "yyyy-MM-dd HH:mm:ss");
};
