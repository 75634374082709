import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { apiRouteProfilePost } from "controllers/profile-route-post/api/profile-post/profile-post";
import { Color } from "@material-ui/lab/Alert";
import { TYPE_USER_PROFILE } from "../../../profile-route/redux-store/profile/profile";
import { BACKEND_HOST, kyRequest } from "../../../../api/api";

type Response = {
    inProgress: boolean;
    response: {
        data: any;
        result: boolean;
        errors: any;
        error: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export const slicerProfilePost = createSlice({
    name: "profilePost",
    initialState: {
        view: {} as Record<string, string>,
        entity: null as TYPE_USER_PROFILE,
        condition: {
            inProgress: false,
            notice: {
                visibility: false,
                severity: "error",
            },
            response: {
                result: false,
                data: null,
                errors: null,
                error: {
                    code: 0,
                    message: "empty",
                },
                message: "",
            },
        } as Response,
    },
    reducers: {
        reset: (state) => {
            state.entity = null as TYPE_USER_PROFILE;
        },
        onNoticeHide: state => {
            state.condition.notice.visibility = false;
        },
        on_Request: state => {
            state.condition = {
                notice: {
                    visibility: false,
                    severity: "success",
                },
                inProgress: true,
                response: {
                    result: false,
                    data: null,
                    errors: null,
                    error: {
                        code: 0,
                        message: "",
                    },
                    message: "",
                },
            };
        },
        onResponse: (state, action) => {
            // state.condition = {
            //     inProgress: false,
            //     response: action.payload,
            // };
        },
        on_Response_Success_Post: (
            state,
            action: PayloadAction<{
                data: TYPE_USER_PROFILE;
                result: boolean;
                error: {
                    message: string;
                    code: number;
                };
                nativeResponse: {
                    ok: boolean;
                    redirected: boolean;
                    status: number;
                    statusText: string;
                    type: string;
                    url: string;
                };
            }>,
        ) => {
            state.condition = {
                notice: {
                    visibility: true,
                    severity: "success",
                },
                inProgress: false,
                response: {
                    result: action.payload.result,
                    data: action.payload.data,
                    errors: null,
                    error: {
                        code: action.payload.error.code,
                        message: action.payload.error.message,
                    },
                    message: action.payload.error.message,
                },
            };
            state.entity = null as TYPE_USER_PROFILE;
        },
        on_Response_Failure: (state, action: PayloadAction<any>) => {
            state.condition.inProgress = false;
            let response = null as any;
            try {
                response =
                    typeof action.payload?.error !== "string"
                        ? action.payload?.error
                        : JSON.parse(action.payload.error);
            } catch (e) {
                response = action.payload?.error;
            }
            const message = (response ? response.message || response : action.payload?.message) || "Ошибка запроса";
            state.condition = {
                inProgress: false,
                response: {
                    result: false,
                    errors: action.payload?.data,
                    data: {},
                    error: response,
                    message,
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        on_NoResponse: state => {
            state.condition.inProgress = false;
        },
        onChange: (state, action) => {
            let value = action.payload.value;
            let [name, title] = action.payload.name.split(".");
            if (state.condition.response.errors) {
                if (name !== "password_confirmation") state.condition.response.errors[name] = "";
            }
            if (name === "reports") {
                const currentReports = Array.isArray(action.payload.currentReports)
                    ? action.payload.currentReports
                    : null;
                state.entity = {
                    ...state.entity,
                    [name]: currentReports.map((report: any) => {
                        return { ...report, [title]: value };
                    }),
                };
            } else {
                if (name === "birthday")
                    value = value.includes(" ") ? value.substring(0, value.indexOf(" ") + 1) : value;
                if (name === "country") {
                    value = value.hasOwnProperty('name') ? value.name : value;
                }
                state.entity = {
                    ...state.entity,
                    [name]: value,
                };
            }
        },
    },
});

export const {
    reset,
    on_Request,
    onResponse,
    on_Response_Success_Post,
    on_Response_Failure,
    on_NoResponse,
    onChange,
    onNoticeHide,
} = slicerProfilePost.actions;

export const async_Profile_Post = (data: any, callback?: (result: any) => void) => async (dispatch: any) => {
    dispatch(on_Request());

    const result = await apiRouteProfilePost(data).catch(async e => {
        
        try {
            await kyRequest.post(`${BACKEND_HOST}/report`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
                json: {
                    userId: data.id || -1,
                    string: e.toString(),
                    message: e.message,
                    name: e.name,
                    data: Object.keys(data),
                    status: e.response?.status || 0,
                    response: e.response?.text() || "no response object",
                },
            });
        } catch (e2) {
            console.log(e2);
        }
    });
    if (result) {
        dispatch(result.result ? on_Response_Success_Post(result) : on_Response_Failure(result));
    } else {
        dispatch(on_Response_Failure(null));
    }
    if (callback) {
        callback(result);
    }
};

export const reselect_Profile_entity_post = (state: RootState) => {
    return state.profilePost.entity;
};
export const reselect_Profile_condition_post = (state: RootState) => {
    return state.profilePost.condition;
};

export default slicerProfilePost.reducer;
