import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "redux-store/index";
import { editPromoApi, getCompanyApi, postCompanyApi } from "../api/api";
import { makeBaseReduxAction, makeClearCondition, makeConditionReducers } from "../../../helper/redux";
import { Expo } from "../../admin-expo/interfaces/admin-expo";

export type Promo = {
    id: number;
    code: string;
    description: string;
    cost: number;
    deleted_at: string;
    created_at: string;
    updated_at: string;
    max_count: number;
    usage_count: number;
    role: number;
    exponent: boolean;
};

export type Company = {
    address: string;
    bitrix_id: any;
    bitrix_state: any;
    created_at: any;
    deleted_at: any;
    employees_count: number;
    expo_place: number;
    id: number;
    inn: string;
    kpp: string;
    name: string;
    short_name: string;
    ogrn: string;
    parent_id: any;
    promos: Array<Promo>;
    updated_at: any;
    user_id: number;
    exponent?: Expo;
    employee: any;
};
export type Paginator = {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: null;
    to: number;
    total: number;
};

export const slicer_Companies = createSlice({
    name: "companies",
    initialState: {
        paginator: {
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "",
            per_page: 15,
            prev_page_url: null,
            to: 15,
            total: 0,
        } as Paginator,
        items: [],
        company: {} as Company,
        condition: makeClearCondition(),
    },
    reducers: {
        ...makeConditionReducers(),
        onLoadItem: (state, action) => {
            state.company = action.payload.response.data;
        },
        onLoadList: (state, action) => {
            state.items = action.payload.response.data;
            state.paginator = {...action.payload.nativeResponse.body };
        },
    },
});

export const { onRequest, onNoticeHide, stopLoading, onLoadList, onLoadItem, onFail, clearCondition } =
    slicer_Companies.actions;

const baseStateAsync = makeBaseReduxAction(
    (dispatch: any) => {
        dispatch(onRequest());
    },
    (dispatch, r) => {
        if (r) {
            dispatch(onFail(r));
        }
    },
    dispatch => {
        dispatch(stopLoading());
    },
);

export const asyncLoadCompany = (params?: any, callback?: (result: any) => void) => {
    return baseStateAsync(getCompanyApi, params, callback, (dispatch, result) => {
        dispatch(params?.id ? onLoadItem(result) : onLoadList(result));
    });
};

export const asyncSaveCompany = (body: any, callback?: (result: any) => void) => {
    return baseStateAsync(postCompanyApi, body, callback, (dispatch, result) => {
        console.log(result)
        result?.response.result && dispatch(clearCondition())
    });
};

export const asyncPromoEdit = (
    params?: { id: number; expo: number; premium: number },
    callback?: (result: any) => void,
) => {
    return baseStateAsync(editPromoApi, params, callback, (dispatch, result: any) => {});
};

export const reselectCompany = (state: RootState) => {
    return state.companies.company;
};
export const reselectCompanyCondition = (state: RootState) => {
    return state.companies.condition;
};
export const reselectCompanies = (state: RootState) => {
    return state.companies.items;
};
export const reselectCompaniesPaginator = (state: RootState) => {
    return state.companies.paginator;
};

export default slicer_Companies.reducer;
