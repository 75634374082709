import { kyRequest, BACKEND_HOST, responseCallback } from "api/api";

/**
 * @route api/promo/promo_id
 * @get
 * @post
 */

export const apiPromoPost = async (data: any) => {
    const formData = new FormData();
    formData.append("code", data.code);
    formData.append("description", data.description);
    formData.append("cost", data.cost);
    formData.append("max_count", data.max_count);
    if (data.count > 1) {
        formData.append("count", data.count);
        formData.append("mask", data.mask);
    }
    if (data.role !== null) formData.append("role", `${data.role}`);
    data?.id && formData.append("_method", "PUT");
    const response = await kyRequest.post(`${BACKEND_HOST}/promo/${data.id || ""}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        body: formData,
    });
    return responseCallback(response);
};
