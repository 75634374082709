import React, { useState } from "react";
import Partner from "./PartnerCard/PartnerCard";
import { IPartner } from "../../../../../types/partners";
import classes from "./MainPartners.module.css";
import kdwbot from "../../../../../../styles/img/kdwbot.png";
import { EXPONENTS_TYPE } from "../../../../../../constants/site/common";
import { TEMPLATE_PARTNER } from "../../../../../../constants/site/exponents/companiesOnMainPage";
import { initStateMain } from "../../PartnersBlock";
import { reselectMainPartnersList } from "controllers/admin-expo/admin-expo-get-list/redux-store/admin-expo-get-list";
import { useSelector } from "react-redux";

const MainPartners = () => {
    const [templatePartner] = useState<IPartner>({
        case: EXPONENTS_TYPE.organizers,
        heading: "Официальный партнер",
        key: "site.official_organizers",
        cards: TEMPLATE_PARTNER,
    });
    const mainPartnersList = useSelector(reselectMainPartnersList);
    // const mainPartnersList = Object.values(initStateMain);
    return (
        <div className={classes.mainPartners}>
            {mainPartnersList.find(partner => partner.cards.length !== 0) ? (
                <>
                    {mainPartnersList.map(
                        (partners, index) =>
                            partners?.cards?.length !== 0 && <Partner partner={partners} key={index} />,
                    )}
                </>
            ) : (
                <div className={classes.mainPartners__templates}>
                    <Partner partner={templatePartner} disabled />
                    <img src={kdwbot} alt={"kdw_bot"} />
                </div>
            )}
        </div>
    );
};

export default MainPartners;
