import { Color } from "@material-ui/lab/Alert";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LangEnum } from "controllers/admin-event/enums";
import { Event } from "controllers/admin-event/interfaces/admin-event";
import { RootState } from "redux-store/index";
import { apiEventSave } from "controllers/admin-event/admin-event-post/api/admin-event-post";
import { initialCondition } from "../../../../admin-speaker/admin-speaker-post/redux-store/admin-speaker-post/admin-speaker-post";

export type Response = {
    inProgress: boolean;
    response: {
        result: boolean;
        errors: any;
        message: string;
    };
    notice: {
        visibility: boolean;
        severity: Color;
    };
};

export type Condition = {
    ru: Response;
    en: Response;
    cn: Response;
};

type InitialState = {
    view: Event;
    modalIsOpened: boolean;
    modalTranslationIsOpened: boolean;
    mode: "create" | "edit";
    condition: Condition;
};

const initialEvent: Event = {
    name: {
        ru: "",
        en: "",
        cn: "",
    },
    description: {
        ru: "",
        en: "",
        cn: "",
    },
    forum_themes: {
        ru: [],
        en: [],
        cn: [],
    },
    date: 0,
    end_date: 0,
    curator: {
        ru: "",
        en: "",
        cn: "",
    },
    has_chat: false,
    scene: {
        ru: "",
        en: "",
        cn: "",
    },
    reports: [],
    moderators: [],
    speakers: [],
    stream_link: {
        ru: "",
        en: "",
        cn: "",
    },
    type: {
        ru: "",
        en: "",
        cn: "",
    },
    place: {
        ru: "",
        en: "",
        cn: "",
    },
};

const initialState: InitialState = {
    view: initialEvent,
    modalIsOpened: false,
    modalTranslationIsOpened: false,
    mode: "create",
    condition: {
        ru: initialCondition,
        en: initialCondition,
        cn: initialCondition,
    },
};

export const slicerNewEvent = createSlice({
    name: "newEvent",
    initialState,
    reducers: {
        onConditionClear: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: false,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    errors: null,
                    message: "",
                },
            };
        },
        onNoticeHide: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang].notice.visibility = false;
        },
        onRequest: (state, action: PayloadAction<{ lang: LangEnum }>) => {
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: true,
                notice: {
                    visibility: false,
                    severity: "error",
                },
                response: {
                    result: false,
                    errors: null,
                    message: "",
                },
            };
        },
        onResponseFail: (state, action: PayloadAction<{ result: any; lang: LangEnum }>) => {
            const response =
                typeof action.payload.result === "string" ? JSON.parse(action.payload.result) : action.payload.result;
            const lang = action.payload.lang;
            (state.condition as Condition)[lang] = {
                inProgress: false,
                response: {
                    result: false,
                    errors: action.payload.result?.errors,
                    message:
                        (response ? response.message || response.error : null) ||
                        action?.payload?.result?.error?.message ||
                        "error",
                },
                notice: {
                    visibility: true,
                    severity: "error",
                },
            };
        },
        onResponseSuccess: (
            state,
            action: PayloadAction<{
                result: any;
                lang: LangEnum;
            }>,
        ) => {
            const response =
                typeof action.payload.result === "string" ? JSON.parse(action.payload.result) : action.payload.result;
            (state.condition as Condition)[action.payload.lang] = {
                inProgress: false,
                response: {
                    result: action.payload.result,
                    errors: null,
                    message: response ? response.message : "",
                },
                notice: {
                    visibility: true,
                    severity: "success",
                },
            };
            state.view.id = action.payload.result.data.id;
        },
        onChange: (state, action) => {
            let ref;
            const nameRoute = action.payload.name.split(".");
            const fieldName = nameRoute.pop();

            if (nameRoute.includes("speakers")) {
                ref = state.view;
                const value = action.payload.value.map((el: any) => {
                    return { ...el };
                });
                ref.speakers = value;
            }
            if (nameRoute.includes("reports")) {
                const value = action.payload.value;
                state.view.reports = state.view.reports?.map((v: any) => (v.id === value?.id ? value : v)) || [value];
            } else {
                ref = nameRoute.reduce((acc: any, item: string) => {
                    // @ts-ignore
                    return acc[item];
                }, state.view);
                if (action.payload.hasOwnProperty("checked")) {
                    ref[fieldName] = action.payload.checked;
                } else {
                    ref[fieldName] = action.payload.value;
                }
            }
        },
        onSetInitialEvent: (state, action: PayloadAction<Event>) => {
            state.view = action.payload;
        },
        onResetEventFormView: state => {
            state.view = initialEvent;
        },
        onSetNewEventModalIsOpened: (state, action: PayloadAction<boolean>) => {
            state.modalIsOpened = action.payload;
        },
        onSetEventFormMode: (state, action: PayloadAction<"create" | "edit">) => {
            state.mode = action.payload;
        },
        onSetEventModalTranslationIsOpened: (state, action: PayloadAction<boolean>) => {
            state.modalTranslationIsOpened = action.payload;
        },
        onFinish: (state, action: PayloadAction<Event>) => {
            state.modalIsOpened = false;
            state.modalTranslationIsOpened = false;
            state.view = initialEvent;
        },
    },
});

export const {
    onNoticeHide,
    onConditionClear,
    onRequest,
    onResponseSuccess,
    onResponseFail,
    onChange,
    onSetInitialEvent,
    onSetNewEventModalIsOpened,
    onSetEventModalTranslationIsOpened,
    onSetEventFormMode,
    onFinish,
    onResetEventFormView,
} = slicerNewEvent.actions;

export const asyncEventSave = (data?: Event, lang?: LangEnum, callback?: (result: any) => void) => async (
    dispatch: any,
    getState: () => RootState,
) => {
    dispatch(onRequest({ lang: lang }));
    const view = data || getState().adminEvent.new.view;
    let result = await apiEventSave(view, lang).catch(e => console.error(e));
    if (result) {
        if (result.result) {
            dispatch(onResponseSuccess({ result: result, lang: lang }));
        } else {
            dispatch(onResponseFail({ result: result, lang: lang }));
        }
    } else {
        dispatch(onResponseFail({ result: result, lang: lang }));
    }
    if (callback) {
        callback(result);
    }
};

export const reselectEventNew = (state: RootState) => {
    return state.adminEvent.new.view;
};
export const reselectEventNewCondition = (state: RootState) => {
    return state.adminEvent.new.condition;
};
export const reselectEventNewModalIsOpened = (state: RootState) => {
    return state.adminEvent.new.modalIsOpened;
};
export const reselectEventModalTranslationIsOpened = (state: RootState) => {
    return state.adminEvent.new.modalTranslationIsOpened;
};
export const reselectEventNewFormMode = (state: RootState) => {
    return state.adminEvent.new.mode;
};

export default slicerNewEvent.reducer;
